import React from 'react';
// import { NavLink } from 'react-router-dom';
import logo from '../images/logo.svg';

/* eslint-disable */
const NavBar = ({ initWidget, markets }) => {
    return (
        <nav
            className="navbar navbar-expand-lg navbar-light bg-light"
            style={{ zIndex: '10', padding: '15px' }}
        >
            <a
                className="navbar-brand"
                to="/"
                style={{ marginTop: '-12px' }}
            >
                <img src={logo} height="23" alt="Everest" />
            </a>
            <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon" />
            </button>
            <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a
                            className="nav-link nav-exchange-link"
                            onClick={() => initWidget('buy')}
                        >
                            Buy Crypto
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link nav-exchange-link"
                            onClick={() => initWidget('swap')}
                        >
                            Swap
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link nav-exchange-link"
                            onClick={markets}
                        >
                            Markets
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link nav-exchange-link"
                            href="https://foundationnetwork.org"
                            target="_blank"
                        >
                            Learn
                        </a>
                    </li>
                </ul>
                <div className="d-flex">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a
                                className="nav-link nav-exchange-connect-btn nav-con-mb"
                                onClick={() => initWidget('connect')}
                            >
                                Connect Wallet
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link nav-exchange-signup-btn text-center"
                                onClick={() => initWidget('signIn')}
                            >
                                Sign In
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
