import React, { useEffect, createRef, useState } from 'react';
import Marquee from "react-fast-marquee";
import { SDK_APP_KEY, SDK_URL } from '../env';
import NavBar from './navbar';
import Banner from '../images/exchange-camp-banner.png';
import GiftIcon from '../images/exchange-gift-icon.svg';
import ExchangeFeature from '../images/exchange-feature.png';
import TickMark from '../images/referral-tick.svg';
import Stocks from '../images/stocks.svg';
import PadLock from '../images/pad-lock.svg';
import ProdService from '../images/prod-serv.png';
import SwapTrade from '../images/swap-trade.svg';
import Discord from '../images/exch-discord.svg';
import Medium from '../images/exch-medium.svg';
import Twitter from '../images/exch-twitter.svg';
import DiscordBlue from '../images/exch-discord-blue.svg';
import MediumBlue from '../images/exch-medium-blue.svg';
import TwitterBlue from '../images/exch-twitter-blue.svg';

const BulletPoints = ({ text, bold, margin }) => {
    return (
        <div className={`row t-follower ${margin === 1 ? 'mt-20' : ''}`}>
            <div className="col-1 affiliate-bullet ref2-points-bullet">
                <img
                    className="everest-follower-image"
                    src={TickMark}
                    alt="tick mark"
                />
            </div>
            <div className="col-11 ref2-points-text">
                <p
                    className={`${bold ? 'font-bold' : ''}`}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            </div>
        </div>
    );
};

const ExchangeRewards = () => {
    const ref = createRef();

    const [twitterHover, setTwitterHover] = useState(false);
    const [mediumHover, setMediumHover] = useState(false);
    const [discordHover, setDiscordHover] = useState(false);

    useEffect(() => {
        let refValue;
        if (ref.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src =
                'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
            script.async = true;
            script.innerHTML = `{
                    "width": "100%",
                    "height": 700,
                    "defaultColumn": "overview",
                    "screener_type": "crypto_mkt",
                    "displayCurrency": "USD",
                    "colorTheme": "light",
                    "locale": "en"
            }`;
            ref.current.appendChild(script);
            refValue = ref.current;
        }

        return () => {
            if (refValue) {
                while (refValue.firstChild) {
                    refValue.removeChild(refValue.firstChild);
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initWidget = (mode) => {
        let sdkURL = SDK_URL;
        if(mode === 'swap') {
            sdkURL += "/swap"
        } else if(mode === 'signUp') {
            sdkURL += "/signup"
        } else if(mode === 'signIn') {
            sdkURL += "/signIn"
        } else if(mode === 'connect') {
            sdkURL += "/connect-wallet"
        }
        sdkURL += "?appKey="+SDK_APP_KEY+"&campaign=exchange"

        window.open(sdkURL, '_blank');
    };

    const markets = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="container-fluid px-0 overflow-hidden exchange-campaign">
            <NavBar initWidget={initWidget} markets={markets} />
            <div className="exch-banner">
                <img
                    className="img-fluid exchange-banner"
                    src={Banner}
                    alt="banner"
                />
                <div className="exch-banner-text">
                    <h1 className="exch-banner-heading">
                        GET YOUR{' '}
                        <span className="exch-banner-reward">
                            $25
                        </span>{' '}
                        WELCOME GIFT*
                    </h1>
                    <h2 className="exch-banner-heading2">
                        & Over <span className="exch-banner-reward2">$25,000</span> in Referral Bonuses
                    </h2>
                    <div className="d-grid gap-2 col-md-6 col-lg-6 col-12">
                        <button
                        className="nav-exchange-signup-btn exch-banner-btn banner-signup"
                        onClick={() => initWidget('signUp')}
                        >
                            Sign Up
                        </button>
                    </div>
                    
                </div>
            </div>
            <div className="exch-rewards" onClick={() => initWidget('signUp')}>
                <Marquee pauseOnHover={true}>
                    <div className='slider-item'>
                        <img
                            className="exch-gift-icon"
                            src={GiftIcon}
                            alt="gift"
                            width={25}
                        />
                        Get a <span className="exch-gift-clr">$25</span> Welcome
                        Gift
                    </div>
                    <div className='slider-item'>
                        <img
                            className="exch-gift-icon"
                            src={GiftIcon}
                            alt="gift"
                            width={25}
                        />
                        Earn Over <span className="exch-gift-clr">$25,000</span> in
                        Referral Bonuses
                    </div>
                    <div className='slider-item'>
                        <img
                            className="exch-gift-icon"
                            src={GiftIcon}
                            alt="gift"
                            width={25}
                        />
                        Sign up now to{' '}
                        <span className="exch-gift-clr">claim gifts</span>
                    </div>
                </Marquee>
            </div>
            <div className="row exch-features-parent">
                <div className='col-xl-6 col-lg-8 col-md-10 col-12 mx-auto'>
                    <div className='row'>
                        <div className="col-md-6 exch-features">
                            <h1 className='exch-heading'>
                                <b>Buy, Swap, Sell, Save & Earn!</b>
                            </h1>
                            <BulletPoints
                                margin={0}
                                bold={true}
                                text={'Lowest Fees in Crypto'}
                            />
                            <BulletPoints bold={true} text={'Over 100+ Tokens'} />
                            <BulletPoints bold={true} text={'No KYC*'} />
                            <BulletPoints
                                bold={true}
                                text={'Buy/Swap/Sell Globally'}
                            />
                            <BulletPoints
                                bold={true}
                                text={'Best Referral Program in Industry'}
                            />
                            <BulletPoints
                                bold={true}
                                text={'Self-Custody Your Tokens'}
                            />
                            <div className="d-grid gap-2 col-md-6 col-lg-6 col-10 get-started" style={{marginTop: "25px"}}>
                                <a
                                href={() => false}
                                className="nav-link nav-exchange-connect-btn nav-con-mb exch-banner-btn text-center"
                                onClick={() => initWidget('swap')}
                                >
                                    Get Started
                                </a>
                            </div>
                            
                        </div>
                        <div className="col-md-6 exch-feature-image">
                            <img
                                className="img-fluid"
                                src={ExchangeFeature}
                                alt="feature"
                                style={{ width: '65%' }}
                            />
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-md-10 mx-auto">
                    <h1 className="text-center" style={{ margin: '70px 0px' }}>
                        <img src={Stocks} alt="stock" width={50} style={{marginTop: "-15px"}} />{' '}
                        <b>Trending Tokens</b>
                    </h1>
                    <div className="tradingview-widget-container">
                        <div ref={ref} className="tradingview-widget-container__widget"></div>
                        <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Track all markets on TradingView</span></a></div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ margin: '70px 0px' }}>
                <div className="d-grid gap-2 col-md-4 col-lg-2 col-6 mx-auto">
                    <button
                        className="nav-exchange-connect-btn blue-hover"
                        onClick={() => initWidget('buy')}
                    >
                        Buy Now
                    </button>
                </div>
            </div>

            <div className="row exch-security">
                <div className="col-md-12">
                    <h1 className='text-center mb-35'>
                        <img className="exch-sec-prop" src={PadLock} alt="lock" width={30} style={{marginTop: "-15px"}} />
                        <b>Security & Protection</b>
                    </h1>

                    <BulletPoints
                        margin={1}
                        text={
                            'From the inception of Everest the security and protection of vital data has been the prime design consideration. Every decision has been made with the goal of increasing the security of the system.'
                        }
                    />
                    <BulletPoints
                        margin={1}
                        text={
                            'Security is layered throughout the Everest platform to protect the user and transaction data at all times. A distributed system based upon the strongest encryption algorithms, paired with proprietary user-controlled identity storage, and state-of-the-art biometric systems ensure that user data is completely protected.'
                        }
                    />
                    <BulletPoints
                        margin={1}
                        text={
                            'That same care and thought went into the mobile and web applications, the application programming interface (API), and the other data interfaces throughout the platform.'
                        }
                    />
                </div>
            </div>

            <div className="exch-banner-2">
                <div className="text-center">
                    <h1 className="exch-footer-reward">
                        Get Your <span className='exch-footer-amount'>$25</span> Welcome Gift
                    </h1>
                    <div className="d-grid gap-2 col-md-6 col-lg-6 col-12 mx-auto">
                        <button
                            className="nav-exchange-signup-btn exch-banner-btn exch-footer-btn white-hover"
                            onClick={() => initWidget('signUp')}
                        >
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>

            <div className="exch-service-wrapper">
                <div className="row exch-service">
                    <div className="col-md-12">
                        <h1 className="text-center mb-35">
                            <img src={SwapTrade} alt="stock" width={50} />{' '}
                            <b>Products & Services</b>
                        </h1>
                        <div style={{ padding: '10px', fontSize: '18px' }}>
                            Solutions are built using the financial services toolbox provided by the Everest platform. Those solutions are able to be used to solve the many value transfer, tracking, and delivery challenges faced every day. <a className='learn-more' href="https://everest.org/" rel="noreferrer" target="_blank"><b>Learn More</b></a>
                        </div>
                    </div>
                </div>
                <div className="row exch-prod-serv">
                    <div className="col-12 text-center">
                        <img src={ProdService} className='prod-serv-image' alt="service" />
                    </div>
                </div>
            </div>

            <div className="exch-banner-footer">
                <div className="mt-50 text-center">
                    <a
                        href="https://twitter.com/EverestDotOrg"
                        target="_blank"
                        rel="noreferrer" 
                        onMouseEnter={() => setTwitterHover(true)}
                        onMouseLeave={() => setTwitterHover(false)}
                    >
                        <img
                            className="ref-social-icons"
                            src={twitterHover ? TwitterBlue : Twitter}
                            alt="twitter"
                        />
                    </a>
                    <a
                        href="https://www.discord.com/invite/YQMP4aTYAY"
                        target="_blank"
                        rel="noreferrer" 
                        onMouseEnter={() => setDiscordHover(true)}
                        onMouseLeave={() => setDiscordHover(false)}
                    >
                        <img
                            className="ref-social-icons ref-social-margin"
                            src={discordHover ? DiscordBlue : Discord}
                            alt="discord"
                        />
                    </a>
                    <a
                        href="https://everestdotorg.medium.com/"
                        target="_blank"
                        rel="noreferrer" 
                        onMouseEnter={() => setMediumHover(true)}
                        onMouseLeave={() => setMediumHover(false)}
                    >
                        <img
                            className="ref-social-icons ref-social-margin"
                            src={mediumHover ? MediumBlue : Medium}
                            alt="medium"
                        />
                    </a>
                </div>
                <p className="mt-50 exch-footer-text exch-footer-terms">
                    *TERMS AND CONDITIONS OF PROMOTION: 
                    <span className="exch-footer-text exch-footer-terms exch-footer-sub">
                        To receive your $25 of welcome rewards, sign up to the Everest platform and complete two transactions in 60 days; Buy $1,000 (or more) of crypto and Swap $1,000 (or more) of crypto. Welcome rewards come from a pool of 5,000,000 ID tokens, on a first come, first served basis. Referral rewards are earned from a portion of the fees incurred by users who sign up using your referral code, for one year from when they sign up, and are paid 60 days after the end of the year. **No KYC on purchases under $1,000.
                    </span>
                </p>
            </div>
        </div>
    );
};

export default ExchangeRewards;
